<template>
    <div class="main recycle">
        <my-header title="回收站" :leftArrow="true"></my-header>
        <div class="page_search">
            <div class="search_list">
                <van-pull-refresh v-model="search.refreshing" @refresh="onRefresh">
                    <van-divider v-if="search.count>0">检索到<b>{{search.count}}</b>个相关品种</van-divider>
                    <van-divider v-else-if="search.word&&search.count==0">
                        <van-button icon="plus" type="success" size="small" @click="$router.push({name:'add',params:{ title:search.word, typeid:search.typeid}})">添加该品种</van-button>
                    </van-divider>
                    <van-divider v-else-if="search.finished"></van-divider>
                    <van-empty v-if="search.count==-1&&search.keyword==''&&search.finished" image="search" />
                    <van-list v-model:loading="search.loading" :finished="search.finished" :finished-text="search.count>0?'没有更多了':''" :error.sync="search.error" error-text="请求失败，点击重新加载" offset="100" @load="onLoad">
                        <van-swipe-cell v-for="item in search.list" :key="item.id" :class="['item',{'isok':item.inprice>0&&item.price>0}]">
                            <van-cell :border=false @click="$router.push({ path: '/wiki/detail/' + item.id })">
                                <van-badge :class="['litpic_box',{'nophotos':item.photos.length==0}]" :content="item.photos.length" :max="10">
                                    <van-image v-if="item.thumb" width="2rem" height="2rem" fit="cover" lazy-load :src="item.thumb">
                                        <template v-slot:loading>
                                            <van-loading type="spinner" size="20" /> </template>
                                    </van-image>
                                    <van-icon v-else class="nolitpic" name="photo" />
                                </van-badge>
                                <span class="title">{{item.title}}<em v-if="item.cname">({{item.cname}})</em><i>[{{getCnameBytypeid(item.typeid)}}]</i>
                                </span>
                                <span :class="['outprice','fr',{'transparent':item.price==0}]">￥<b>{{item.price}}</b>/{{item.price_unit}}</span>
                                <span :class="['inprice','fr',{'noinprice':item.inprice<=0 && item.pubprice>0}]"><i v-if="item.inprice>0"><em>￥</em><b>{{item.inprice}}</b></i><i v-if="item.pubprice>0"><em>￥</em><b>{{item.pubprice}}</b></i><i v-if="item.pubprice==0&&item.inprice==0">询价</i><i v-if="item.supplier">{{item.supplier.substr(0, 2)}}</i></span>
                            </van-cell>
                            <template #right>
                                <van-button type="success" square @click="recovery(item.id)">还原</van-button>
                                <van-button type="danger" square @click="clear(item)">清除</van-button>
                            </template>
                        </van-swipe-cell>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";

import { Search, } from 'vant';

export default {
    components: {
        [Wiki.name]: Wiki,
        [Search.name]: Search,
    },
    name: 'recycle',
    data() {
        return {
            search: {
                count: -1,
                list: [],
                finished: true,
                loading: false,
                refreshing: false,
                error: false,
                pagesize: 15,
                page: 1,
            },
            conf:this.$store.get('conf')
        };
    },
    created: function() { this.onSearch(); },
    methods: {
        //检索
        onSearch(page) {
            if (page == 1) {
                this.search.list = [];
                this.search.count = -1;
            }

            this.search.page = page;
            this.search.loading = true;
            this.search.finished = false;
            this.search.error = false;

            Wiki.recycle({ 'pagesize': this.search.pagesize, 'page': page })
                .then(res => {
                    this.search.loading = false;
                    if (this.search.refreshing) {
                        this.search_init();
                    }
                    if (res.data.data.length > 0 && page > 1) {
                        this.search.list = this.search.list.concat(res.data.data);
                    } else {
                        this.search.list = res.data.data;
                    }
                    this.search.count = res.data.total;
                    if (this.search.list.length >= this.search.count) {
                        this.search.finished = true;
                    } else {
                        this.search.finished = false;
                    }
                    this.search.page = res.data.current_page + 1;
                }).catch(() => {
                });
        },
        //搜索复原
        search_init() {
            this.search.list = [];
            this.search.count = -1;
            this.search.finished = true;
            this.search.refreshing = false;
            this.onSearch();
        },
        //检索下拉刷新
        onRefresh() {
            this.search.finished = false;
            this.search.refreshing = false;
            this.search.page = 1;
            this.onSearch(this.search.keyword, this.search.page);
        },
        //检索上拉加载
        onLoad() {
            this.search.loading = true;
            this.onSearch(this.search.page);

        },
        //还原
        recovery(id) {
            Wiki.recovery({ 'id': id })
                .then(res => {
                    this.$toast.success(res.msg);
                    this.search_init();
                }).catch(() => {
                    this.search_init();
                });

        },
        //清除
        clear(item) {

            if(item.thumb){
                this.$toast('清除前请先删除缩略图');
                return false;
            }
            if(item.photos.length>0){
                this.$toast('清除前请先清空相册');
                return false;
            }

            this.$dialog.confirm({
                    title: '提醒',
                    message: '清除后不可恢复',
                })
                .then(() => {
                    Wiki.clear({ 'id': item.id }).then(res => {
                        this.$toast.success(res.msg);
                        this.search_init();
                    });
                }).catch(() => {

                });
        },
        //通过分类名称获取分类ID
        getTypeidByname(typename) {
            let types = this.conf.types;
            for (let i in types) {
                if (typename == types[i]['cname']) {
                    return types[i]['id'];
                }
            }
        },
        //通过分类ID获取分类别名
        getCnameBytypeid(typeid) {
            let types = this.conf.types;
            for (let i in types) {
                if (typeid == types[i]['id']) {
                    return types[i]['cname'];
                }
            }
        },
    },
    computed: {},
    watch: {}
};
</script>
<style lang="less">
.recycle .page_search {
    padding-top: 10px
}
</style>